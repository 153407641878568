<template>
  <div class="product-avatar" @click="clickProduct"
       :class="{ 'selected': selected, 'clickable': clickable || show_desc_on_click, 'vertical': vertical }">
    <div class="product-container"
         >
      <div class="product-check" v-if="clickable && !vertical">
        <i class="fa fa-check" v-if="selected "></i>
      </div>
      <div v-if="productIcon" class="product-icon">
        <i class="fa" :class="productIcon"></i>
      </div>
      <div class="product-container inner">
        <div class="product-content" :class="{'addon': isAddon}">
          <h3 class="text-left">
            {{ product.name }}
          </h3>
          <div class="text-left" v-if="!hide_description">
            {{ product.description }}
          </div>
        </div>
        <div class="product-price text-right" :class="{'addon': isAddon}" v-if="!vertical">
          <i class="fa fa-plus mr-1" v-if="isAddon"></i>
          {{ formattedPrice }}
        </div>
      </div>
      <div class="product-check" v-if="clickable && vertical">
        <i class="fa fa-check" v-if="selected "></i>
      </div>
    </div>
    <div :class="{'show': (always_show_description || selected || vertical || expanded) && htmlDescription}"
      class="product-html-description"
         v-html="htmlDescription">
    </div>
    <div class="product-price" :class="{'addon': isAddon}" v-if="vertical">
      <i class="fa fa-plus mr-1" v-if="isAddon"></i>
      {{ formattedPrice }}
    </div>
  </div>
</template>
<script>

export default {
  name: 'product-avatar',
  props: {
    product: Object,
    selected: Boolean,
    clickable: Boolean,
    hide_description: Boolean,
    vertical: Boolean,
    always_show_description: Boolean,
    show_desc_on_click: Boolean
  },
  data: function () {
    return {
      expanded: false
    }
  },
  async mounted() {
    console.log('Product Avatar Data: ', this.product)
  },
  methods: {
    clickProduct() {
      if (this.show_desc_on_click) {
        this.expanded = !this.expanded
      }
      this.$emit('click', this.product)
    }
  },
  computed: {
    htmlDescription() {
      if (Object.hasOwn(this.product.data, 'html_description')) {
        return this.product.data.html_description
      } else {
        return ''
      }
    },
    formattedPrice () {
      let billableUnitLookup = {
        'tracked_assets': 'vehicle',
        'untracked_assets': 'asset',
        'all_assets': 'asset',
        'flat_rate': '',
        'users': 'user'
      }
      if (this.product.price_usage_type === 'metered') {
        let unitType = billableUnitLookup[this.product.billable_unit]
        return `AU$${this.product.price / 100}/${unitType}/${this.product.price_interval_unit}`
      } else {
        return `AU$${this.product.price / 100}/${this.product.price_interval_unit}`
      }
      // if (this.product.price_interval_count === 1) {
      //   suffix = '/' + this.product.price_interval_unit
      // } else {
      //   suffix = '/' + this.product.price_interval_count + ' ' + this.product.price_interval_unit + 's'
      // }
    },
    isAddon() {
      return this.product.product_type === 'addon'
    },
    productIcon() {
      console.log(this.product.data)
      if (Object.hasOwn(this.product.data, 'product_icon')) {
        return this.product.data.product_icon
      } else {
        return null
      }
    }
  },
  watch: {
    products: async function (newVal) {
      this.updateProducts(newVal)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../variables';

.product-avatar {
  //display: flex;
  //flex-direction: column;
  //width: 100%;
  display: flex;
  flex-direction: column;
  background: $theme-color-background-2;
  border: 1px solid $theme-color-primary-5;
  border-radius: 10px;
  margin: 10px 5px;
  justify-items: center;
  justify-content: space-between;
}

.product-avatar.vertical {
  align-items: center;
}

.product-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  border-radius: 10px;
  flex-grow: 1;
  padding: 5px;
}

.product-check {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  font-size: 2em;
  color: $theme-color-primary-2;
  width: 2em;
  min-height: 48px; // height of the fa-check
}

.product-content {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  font-size: 0.75em;
  flex-grow: 4;
}

.product-content h3 {
  margin-bottom: 0;
}

.product-content.addon h3 {
  font-size: 1.5em;
}

.product-price {
  display: flex;
  justify-content: flex-end;
  align-content: center;
  align-items: center;
  font-size: 1.5em;
  font-weight: 400;
  color: $theme-color-primary-2;
  flex-grow: 1;
}

.product-price.addon {
  font-size: 1.2em;
}

.product-row:hover {
  background: $theme-color-background-1;
  border-color: $theme-color-primary-3;

  h3 {
    text-wrap: avoid;
    color: white;
  }
}

.selected {
  background: $theme-color-background-1;
  outline: 2px solid $theme-color-primary-3;
}

.product-html-description {
  display: flex;
  flex-flow: column wrap;
  align-content: center;
  transition: max-height 0.3s, visibility 0.5s 0s;
  max-height: 0;
  visibility: hidden;
  overflow: hidden;
  text-wrap: nowrap;
  padding-left: 2em;
  text-align: left;
}

.product-html-description.show {
  max-height: 500vh;
  visibility: visible;
}

.product-icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  font-size: 2em;
  margin: 0 0.2em 0 0.2em;
}


@media screen and (max-width: 600px) {
  .product-container.inner {
    flex-wrap: wrap;
    font-size: 0.9em;
  }
  .product-check {
    font-size: 1.2em;
    min-height: auto;
    width: auto;
  }
  .product-html-description {
    font-size: 0.6em;
  }
  .product-price {
    font-size: 0.9em;
  }
}

@media screen and (max-width: 420px) {
  h3 {
   font-size: 1.5em;
  }
}
</style>
