var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "product-avatar",
      class: {
        selected: _vm.selected,
        clickable: _vm.clickable || _vm.show_desc_on_click,
        vertical: _vm.vertical
      },
      on: { click: _vm.clickProduct }
    },
    [
      _c("div", { staticClass: "product-container" }, [
        _vm.clickable && !_vm.vertical
          ? _c("div", { staticClass: "product-check" }, [
              _vm.selected ? _c("i", { staticClass: "fa fa-check" }) : _vm._e()
            ])
          : _vm._e(),
        _vm.productIcon
          ? _c("div", { staticClass: "product-icon" }, [
              _c("i", { staticClass: "fa", class: _vm.productIcon })
            ])
          : _vm._e(),
        _c("div", { staticClass: "product-container inner" }, [
          _c(
            "div",
            { staticClass: "product-content", class: { addon: _vm.isAddon } },
            [
              _c("h3", { staticClass: "text-left" }, [
                _vm._v(" " + _vm._s(_vm.product.name) + " ")
              ]),
              !_vm.hide_description
                ? _c("div", { staticClass: "text-left" }, [
                    _vm._v(" " + _vm._s(_vm.product.description) + " ")
                  ])
                : _vm._e()
            ]
          ),
          !_vm.vertical
            ? _c(
                "div",
                {
                  staticClass: "product-price text-right",
                  class: { addon: _vm.isAddon }
                },
                [
                  _vm.isAddon
                    ? _c("i", { staticClass: "fa fa-plus mr-1" })
                    : _vm._e(),
                  _vm._v(" " + _vm._s(_vm.formattedPrice) + " ")
                ]
              )
            : _vm._e()
        ]),
        _vm.clickable && _vm.vertical
          ? _c("div", { staticClass: "product-check" }, [
              _vm.selected ? _c("i", { staticClass: "fa fa-check" }) : _vm._e()
            ])
          : _vm._e()
      ]),
      _c("div", {
        staticClass: "product-html-description",
        class: {
          show:
            (_vm.always_show_description ||
              _vm.selected ||
              _vm.vertical ||
              _vm.expanded) &&
            _vm.htmlDescription
        },
        domProps: { innerHTML: _vm._s(_vm.htmlDescription) }
      }),
      _vm.vertical
        ? _c(
            "div",
            { staticClass: "product-price", class: { addon: _vm.isAddon } },
            [
              _vm.isAddon
                ? _c("i", { staticClass: "fa fa-plus mr-1" })
                : _vm._e(),
              _vm._v(" " + _vm._s(_vm.formattedPrice) + " ")
            ]
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }